import React, {useContext} from 'react'
import SellhomeImg from '../../../images/sell_home.png'
import polygon1 from '../../../images/Polygon-1.png'
import polygon2 from '../../../images/Polygon-2.png'
import polygon3 from '../../../images/Polygon-3.png'
import polygon4 from '../../../images/Polygon-4.png'
import { Link } from 'gatsby'
import Button from '../../Button/Button'
import ModalContext from '../../context/ModalContext'
import { StaticImage } from "gatsby-plugin-image"

const SellHome = () => {
 const {setModalvisible, setPopupContent} = useContext(ModalContext);

  return (
    <>
      <div className="pt-120 pb-[80px] md:pt-[132px] sm:pt-0 sm:pb-[20px]">
        <div className="container md:px-0">
          <div className="flex items-center md:gap-[14px] justify-between md:flex-col-reverse md:justify-start md:items-start">
            <div className="max-w-[560px] md:px-[17px] w-full lg:mr-[30px] md:mt-[20px] md:max-w-[500px] md:max-w-[100%] md:w-full md:mr-[0px] md:mb-[50px]">
              <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-600 pb-24 sm:text-sm-3xl">
                Sell Your
                <span className="font-Quicksand font-500"> Bankers Hill</span> Home
              </h1>
              <p className="text-d-3lg font-500 font-Quicksand pb-[24px] text-[#666666]">
              Find out how much your home in Bankers Hill is really worth from experienced real estate professionals, rather than relying on an algorithm that gives you inaccurate information. And when you’re ready to sell, we’ll help you with the listing and find you a great buyer.
              </p>

              <Link
                to=""
                className="xs:max-w-[48%] xs:w-full"
              >
                <Button
                onClick={() => {
                  setPopupContent('homeValuation')
                  setModalvisible(true)
                }}
                  type="solid"
                  color="white"
                  customStyle="px-[16px] xs:px-[0] xs:w-full xs:justify-center"
                >
                  <p>What’s my Home Worth?</p>
                </Button>
              </Link>
            </div>
            <div className="w-fit relative">
            <div className="relative p-[30px]">
            <StaticImage src="../../../images/BankerHill-LP.png" alt="sellHome" />
            </div>
              {/* <img src={SellhomeImg} className="relative" alt="sellHome" /> */}
              
          
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SellHome
